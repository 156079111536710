import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import GoogleMapReact from "google-map-react";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Logo from "common/components/UIElements/Logo";
import Container from "common/components/UI/Container";
import FooterWrapper, { List, ListItem } from "./footer.style";

import LogoImage from "common/assets/image/midiagnostico-logo.png";
import Link from "common/components/Link";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
  subtitleStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      landingJson {
        FOOTER_WIDGET {
          title
          phone
          address
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Logo"
              logoStyle={logoStyle}
            />
            <Link href={`mailto:contacto@midiagnostico.cl`} {...textStyle}>
              contacto@midiagnostico.cl
            </Link>
            <Link href={`mailto:laboratorio@midiagnostico.cl`} {...textStyle}>
              laboratorio@midiagnostico.cl
            </Link>
            {/* <Text content="Calama:" {...titleStyle} />
            <Text content="Cobija" {...subtitleStyle} />
            <Text content="Direccion" {...textStyle} />
            <Text content="+56 55 2312 493" {...textStyle} />
            <Text content="Villa Ayquina" {...subtitleStyle} />
            <Text content="Direccion" {...textStyle} />
            <Text content="+56 55 2517 300" {...textStyle} />
            <Text content="Maipú:" {...titleStyle} />
            <Text content="Address" {...textStyle} />
            <Text content="+56 22 505 3784" {...textStyle} />
            <Text content="Oficina Chaxa:" {...titleStyle} />
            <Text content="+56 22 716 5487" {...textStyle} />
            <Text content="Exámenes a domicilio:" {...titleStyle} />
            <Text
              content="Av. Presidente Kennedy 5770, Oficina 1404"
              {...textStyle}
            />
            <Text content="+56 9 4223 6206" {...textStyle} /> */}
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.landingJson.FOOTER_WIDGET.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  <ListItem>
                    <p className="ListItem">{widget.address}</p>
                  </ListItem>
                  <ListItem>
                    <a href={`tel:${widget.phone}`} className="ListItem">
                      {widget.phone}
                    </a>
                  </ListItem>
                  <ListItem>
                    <a href={`mailto:${widget.email}`} className="ListItem">
                      {widget.email}
                    </a>
                  </ListItem>
                </List>
              </Box>
            ))}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.9569700398124!2d-70.57613788377793!3d-33.39828680222519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662ced62ad9edb5%3A0xdd728a51f07ef68b!2sAv%20Presidente%20Kennedy%205770%2C%20Vitacura%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1626317070638!5m2!1sen!2scl"
              width="100%"
              height="90%"
              style={{
                border: 0,
              }}
              allowFullScreen={false}
              loading="lazy"
            >
              {" "}
            </iframe> */}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Footer col one style
  colOne: {
    width: [1, "35%", "35%", "23%"],
    mt: [0, "13px"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "65%", "65%", "77%"],
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "18px",
    fontWeight: "700",
    mb: "15px",
  },
  subtitleStyle: {
    fontSize: "16px",
    fontWeight: "550",
    mb: "10px",
  },
  // Default logo size
  logoStyle: {
    width: "300px",
    mb: "30px",
  },
  // widget text default style
  textStyle: {
    color: "rgba(30,30,200)",
    fontSize: "18px",
    mb: "20px",
  },
};

export default Footer;
