import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import NavbarWrapper from "common/components/Navbar";
import Drawer from "common/components/Drawer";
import Button from "common/components/Button";
import Logo from "common/components/UIElements/Logo";
import Box from "common/components/Box";
import HamburgMenu from "common/components/HamburgMenu";
import Container from "common/components/UI/Container";
import { DrawerContext } from "common/contexts/DrawerContext";
import ScrollSpyMenu from "common/components/ScrollSpyMenu";

import LogoImage from "common/assets/image/midiagnostico-logo-white.png";
import LogoImageAlt from "common/assets/image/midiagnostico-logo.png";

import { Icon } from "react-icons-kit";
import { clock } from "react-icons-kit/icomoon/clock";
import { ic_email } from "react-icons-kit/md/ic_email";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { toggle } = createPopup("dRTVtJei", {
    size: 80,
  });

  const Data = useStaticQuery(graphql`
    query {
      landingJson {
        MENU_ITEMS {
          label
          path
          offset
          staticLink
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/#banner"
            logoSrc={LogoImage}
            title="Logo"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/#banner"
            logoSrc={LogoImageAlt}
            title="Logo"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.landingJson.MENU_ITEMS}
              offset={-70}
            />

            <Button
              className="navbar_button"
              {...button}
              title="CONTACTO"
              icon={<Icon icon={ic_email} />}
              iconPosition="left"
              onClick={toggle}
              style={{
                marginRight: 15,
              }}
            />
            <Link to="/#agenda" className="navbar_button">
              <Button
                {...button}
                title="AGENDAR HORA"
                icon={<Icon icon={clock} />}
                iconPosition="left"
              />
            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.landingJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <Button
                className="navbar_drawer_button"
                {...button}
                title="CONTACTO"
                icon={<Icon icon={ic_email} />}
                iconPosition="left"
                onClick={toggle}
                style={{
                  marginRight: 15,
                  marginBottom: 15,
                  width: "100%",
                }}
              />
              <Link to="/#agenda" className="navbar_drawer_button">
                <Button
                  {...button}
                  title="AGENDAR HORA"
                  icon={<Icon icon={clock} />}
                  iconPosition="left"
                />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["170px", "180px"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "700",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "secondaryWithBg",
    minHeight: "auto",
    height: "40px",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
